/* You can add global styles to this file, and also import other style files */
@import "src/variables.scss";
// Required Core Stylesheet

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  color: $dark;
  font-display: swap;
}

html,
body,
main {
  width: 100vw;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-family: $ff-article;
  overflow-x: hidden;
}

// //Montserrat alt
// @font-face {
//     font-family: "MontserratAlt";
//     src: local("Montserrat") url(./assets/fonts/MontserratAlternates-Regular.ttf) format("truetype");
// }
// @font-face {
//     font-family: "MontserratAltBlack";
//     src: local("Montserrat") url(./assets/fonts/MontserratAlternates-Black.ttf) format("truetype");
// }

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: $ff-primary;
}
a,
button {
  font-family: $ff-primary;
}
article,
p,
input,
textarea {
  font-family: $ff-article;
  font-weight: 400;
}
a {
  color: inherit;
  text-decoration: none;
  &:active {
    color: inherit;
  }

  &:hover {
    color: $blue;
  }
}

img {
  pointer-events: none;
}

//Select color
::selection {
  color: $light;
  background: $orange-gradient;
}

//Scrollbar

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(229deg, $blue 0%, $blue-secondary 100%);
  border-radius: 50px;
  transition: 1s;
  width: 12px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(229deg, $dark 0%, $dark-second 100%);
}
::-webkit-scrollbar-thumb:active {
  background: linear-gradient(229deg, $dark 0%, $dark-second 100%);
}
::-webkit-scrollbar-track {
  background: #666666;
  background: transparent;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #e7e7e7;
}
::-webkit-scrollbar-track:active {
  background: #c4c4c4;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
