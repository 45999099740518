$base-color: #c6538c;
$header-height: 50px;

$orange-gradient: #ff774d;
$blue-gradient: #534dff;

$blue: #4d8eff;
$blue-primary: #4d77ff;
$blue-secondary: #275c88;

$purple-primary: #c637b9;
$purple-secondary: #350b6d;

$lilac-primary: #75a2d1;
$lilac-secondary: #453c9e;

$orange-primary: #f87045;
$orange-secondary: #970000;

$red: rgb(255, 52, 52);
$yellow: #f1c143;
$green: #46ff40;

$toastDefault: #eeeeee;
$toastDefaultHover: #d1cfcf;

$toastSuccess: #419c55;
$toastSuccessHover: #5b9c41;

$toastError: rgb(255, 52, 52);
$toastErrorHover: #970000;

$toastProgress: rgba(0, 0, 0, 0.25);

$dark: #333;
$dark-hover: #444;
$dark-active: #666;
$black: #010001;
$dark-transparent-30: rgba(51, 51, 51, 0.333);
$dark-transparent-80: rgba(51, 51, 51, 0.8);
$dark-second: #777;
$clean-white: #fff;
$light: #eeeeee;
$light-second: #f5f5f5;
$light-third: #e0dddd;
$light-80: hsla(0, 0%, 93%, 0.733);
$light-85: rgba(238, 238, 238, 0.85);
$light-90: rgba(238, 238, 238, 0.9);
$light-20: #eeeeee2f;

$font-sm: 12px;
$font-btn: 16px;
$font-md: 24px;
$font-lg: 48px;

$desktop: 700px;
$tablet: 1000px;
$max-width: 1920px;
$input-height: 45px;

$padding-component-side: 10px;
$padding-component-top: 25px;
$padding-section-top: 50px;
$padding-section-sides: 25px;

$box-shadow: 1px 1px 28px 0px rgba(0, 0, 0, 0.75);
$box-shadow-input: 1px 1px 6px 0px rgb(0 0 0 / 25%);
$box-shadow-input-hover: 0px 0px 6px 2px rgb(0 0 0 / 30%);
$box-shadow-small: 1px 1px 28px 0px rgba(0, 0, 0, 0.25);
$box-shadow-btn: 0px 0px 7px 0px rgba(0, 0, 0, 0.302);
$box-shadow-btn-inset: inset 0px 0px 20px 6px rgb(0 0 0 / 25%);
$box-shadow-btn-inset-active: inset 0px 0px 20px 6px rgb(0 0 0 / 50%);

$text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
  0px -5px 35px rgba(255, 255, 255, 0.3);
$text-shadow-white: 0 0 80px rgb(192 219 255 / 75%),
  0 0 32px rgb(65 120 255 / 24%);

$blogg-font-size-mobile: 16px;
$blogg-line-height-mobile: 26px;

$blogg-font-size: 18px;
$blogg-line-height: 29px;

$elevation-1: 0px 1px 1px rgba(18, 18, 18, 0.14),
  0px 2px 1px rgba(18, 18, 18, 0.12), 0px 1px 3px rgba(18, 18, 18, 0.2);
$elevation-2: 0px 4px 5px rgba(18, 18, 18, 0.14),
  0px 1px 10px rgba(18, 18, 18, 0.12), 0px 2px 4px rgba(18, 18, 18, 0.2);
$elevation-3: 0px 8px 10px rgba(18, 18, 18, 0.14),
  0px 3px 14px rgba(18, 18, 18, 0.12), 0px 5px 5px rgba(18, 18, 18, 0.2);
$elevation-4: 0px 16px 24px rgba(18, 18, 18, 0.14),
  0px 6px 30px rgba(18, 18, 18, 0.12), 0px 8px 10px rgba(18, 18, 18, 0.2);
$elevation-5: 0px 24px 38px rgba(18, 18, 18, 0.14),
  0px 9px 46px rgba(18, 18, 18, 0.12), 0px 11px 15px rgba(18, 18, 18, 0.2);

.gradient-line {
  background: linear-gradient(to right, #d06b30 0%, #c43ad6 50%, #30cfd0 100%);
}

@mixin gradientTitle-landing {
  background: #d06b30;
  background: -webkit-linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  background: -moz-linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  background: linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin gradientTitle {
  background: #d06b30;
  background: -webkit-linear-gradient(
    to right,
    #d06b30 0%,
    #c43ad6 50%,
    #30cfd0 100%
  );
  background: -moz-linear-gradient(
    to right,
    #d06b30 0%,
    #c43ad6 50%,
    #30cfd0 100%
  );
  background: linear-gradient(to right, #d06b30 0%, #c43ad6 50%, #30cfd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin ctaText {
  transition: all 0.5s ease;
  background: #b631a7;
  background: -webkit-linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  background: -moz-linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  background: linear-gradient(to right, #30cfd0 0%, #c43ad6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin ctaText-blue {
  transition: all 0.5s ease;
  background: #b631a7;
  background: -webkit-linear-gradient(to right, #0186da 0%, #30cfd0 100%);
  background: -moz-linear-gradient(to right, #0186da 0%, #30cfd0 100%);
  background: linear-gradient(to right, #0186da 0%, #30cfd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin titleText-tjenester {
  transition: all 0.5s ease;
  background: #d06b30;
  background: -webkit-linear-gradient(
    145deg,
    #d06b30 0%,
    #b631a7 40%,
    #30cfd0 100%
  );
  background: -moz-linear-gradient(
    145deg,
    #d06b30 0%,
    #b631a7 40%,
    #30cfd0 100%
  );
  background: linear-gradient(145deg, #d06b30 0%, #b631a7 40%, #30cfd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin ctaButtonText {
  background: #b631a7;
  background: -webkit-linear-gradient(to right, #b631a7 0%, #0186da 100%);
  background: -moz-linear-gradient(to right, #b631a7 0%, #0186da 100%);
  background: linear-gradient(to right, #b631a7 0%, #0186da 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin backgroundShade {
  background-color: #2a2a2a;
  opacity: 0.5;
}
@mixin vaAbs {
  top: 50%;
  transform: translateY(-50%);
}
@mixin haAbs {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@mixin aAbs {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin flexC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@mixin flexR {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 900;
  src: url("/assets/fonts/Mosk900.ttf") format("woff2"),
    url("/assets/fonts/Mosk900.ttf") format("woff"),
    url("/assets/fonts/Mosk900.ttf") format("truetype"),
    url("/assets/fonts/Mosk900.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 100;
  src: url("/assets/fonts/Mosk100.ttf") format("woff2"),
    url("/assets/fonts/Mosk100.ttf") format("woff"),
    url("/assets/fonts/Mosk100.ttf") format("truetype"),
    url("/assets/fonts/Mosk100.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 200;
  src: url("/assets/fonts/Mosk200.ttf") format("woff2"),
    url("/assets/fonts/Mosk200.ttf") format("woff"),
    url("/assets/fonts/Mosk200.ttf") format("truetype"),
    url("/assets/fonts/Mosk200.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  src: url("/assets/fonts/Mosk300.ttf") format("woff2"),
    url("/assets/fonts/Mosk300.ttf") format("woff"),
    url("/assets/fonts/Mosk300.ttf") format("truetype"),
    url("/assets/fonts/Mosk300.ttf") format("svg");
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 400;
  src: url("/assets/fonts/Mosk400.ttf") format("woff2"),
    url("/assets/fonts/Mosk400.ttf") format("woff"),
    url("/assets/fonts/Mosk400.ttf") format("truetype"),
    url("/assets/fonts/Mosk400.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 500;
  src: url("/assets/fonts/Mosk500.ttf") format("woff2"),
    url("/assets/fonts/Mosk500.ttf") format("woff"),
    url("/assets/fonts/Mosk500.ttf") format("truetype"),
    url("/assets/fonts/Mosk500.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 600;
  src: url("/assets/fonts/Mosk600.ttf") format("woff2"),
    url("/assets/fonts/Mosk600.ttf") format("woff"),
    url("/assets/fonts/Mosk600.ttf") format("truetype"),
    url("/assets/fonts/Mosk600.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 700;
  src: url("/assets/fonts/Mosk700.ttf") format("woff2"),
    url("/assets/fonts/Mosk700.ttf") format("woff"),
    url("/assets/fonts/Mosk700.ttf") format("truetype"),
    url("/assets/fonts/Mosk700.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Mosk";
  font-weight: 800;
  src: url("/assets/fonts/Mosk800.ttf") format("woff2"),
    url("/assets/fonts/Mosk800.ttf") format("woff"),
    url("/assets/fonts/Mosk800.ttf") format("truetype"),
    url("/assets/fonts/Mosk800.ttf") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Pier";
  font-weight: 400;
  src: url("/assets/fonts/PierSans-Light.otf") format("woff2"),
    url("/assets/fonts/PierSans-Light.otf") format("woff"),
    url("/assets/fonts/PierSans-Light.otf") format("truetype"),
    url("/assets/fonts/PierSans-Light.otf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Pier";
  font-weight: 500;
  src: url("/assets/fonts/PierSans-regular.otf") format("woff2"),
    url("/assets/fonts/PierSans-regular.otf") format("woff"),
    url("/assets/fonts/PierSans-regular.otf") format("truetype"),
    url("/assets/fonts/PierSans-regular.otf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Pier";
  font-weight: 600;
  src: url("/assets/fonts/PierSans-Medium.otf") format("woff2"),
    url("/assets/fonts/PierSans-Medium.otf") format("woff"),
    url("/assets/fonts/PierSans-Medium.otf") format("truetype"),
    url("/assets/fonts/PierSans-Medium.otf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Pier";
  font-weight: 700;
  src: url("/assets/fonts/PierSans-Bold.otf") format("woff2"),
    url("/assets/fonts/PierSans-Bold.otf") format("woff"),
    url("/assets/fonts/PierSans-Bold.otf") format("truetype"),
    url("/assets/fonts/PierSans-Bold.otf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Pier";
  font-weight: 900;
  src: url("/assets/fonts/PierSans-Black.otf") format("woff2"),
    url("/assets/fonts/PierSans-Black.otf") format("woff"),
    url("/assets/fonts/PierSans-Black.otf") format("truetype"),
    url("/assets/fonts/PierSans-Black.otf") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 900;
  src: url("/assets/fonts/Plex/IBMPlexSans-Bold.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-Bold.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-Bold.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-Bold.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 900;
  font-style: italic;
  src: url("/assets/fonts/Plex/IBMPlexSans-BoldItalic.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-BoldItalic.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-BoldItalic.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-BoldItalic.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 800;
  src: url("/assets/fonts/Plex/IBMPlexSans-SemiBold.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBold.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBold.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBold.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 800;
  font-style: italic;
  src: url("/assets/fonts/Plex/IBMPlexSans-SemiBoldItalic.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBoldItalic.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBoldItalic.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-SemiBoldItalic.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 600;
  src: url("/assets/fonts/Plex/IBMPlexSans-Medium.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-Medium.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-Medium.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-Medium.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 600;
  font-style: italic;
  src: url("/assets/fonts/Plex/IBMPlexSans-MediumItalic.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-MediumItalic.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-MediumItalic.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-MediumItalic.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 500;
  src: url("/assets/fonts/Plex/IBMPlexSans-Regular.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-Regular.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-Regular.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-Regular.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 100;
  font-style: italic;
  src: url("/assets/fonts/Plex/IBMPlexSans-ThinItalic.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-ThinItalic.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-ThinItalic.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-ThinItalic.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 100;
  src: url("/assets/fonts/Plex/IBMPlexSans-Thin.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-Thin.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-Thin.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-Thin.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 300;
  src: url("/assets/fonts/Plex/IBMPlexSans-Light.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-Light.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-Light.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-Light.ttf") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/Plex/IBMPlexSans-LightItalic.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-LightItalic.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-LightItalic.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-LightItalic.ttf") format("svg");
}

@font-face {
  font-display: swap;
  font-family: "Plex";
  font-weight: 200;
  src: url("/assets/fonts/Plex/IBMPlexSans-ExtraLight.ttf") format("woff2"),
    url("/assets/fonts/Plex/IBMPlexSans-ExtraLight.ttf") format("woff"),
    url("/assets/fonts/Plex/IBMPlexSans-ExtraLight.ttf") format("truetype"),
    url("/assets/fonts/Plex/IBMPlexSans-ExtraLight.ttf") format("svg");
}

// $ff-article: "Pier", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
//   Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$ff-mosk: "Mosk", "Pier", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$ff-pier: "Pier", "Plex", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$ff-article: "Plex", "Pier", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$ff-primary: "Mosk", "Pier", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; ;
